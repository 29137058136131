@import "./fonts.css";

.MyBotAvatar {
  width: 50px;
  height: 50px;
  background: transparent;
  padding: 5%;
}

/* For the body colour of the page */
.GrayBody {
  background: #e5e5e5;
}
.WhiteBody {
  background-color: #ffffff;
}

.gradientText {
  background-image: linear-gradient(180deg, #8a2387 0%, #701b6d 100%);
  color: transparent;
  background-clip: text;
}

/* Overriding the Materila UI button property when hovered, clicked */
.button_no_bg,
.button_no_bg:hover,
.button_no_bg::after,
.button_no_bg:focus,
.button_no_bg:active {
  background: #ffffff;
}

.button_gray_bg,
.button_gray_bg:hover,
.button_gray_bg::after,
.button_gray_bg:focus,
.button_gray_bg:active {
  background: #e5e5e5;
}

.password_reset_container {
  position: relative;
  top: 50px;
  padding: 4% 2% 2% 4%;

  background: #ffffff;
  border: 1px solid #ededed;
  box-sizing: border-box;
  box-shadow: 4px 4px 60px rgba(196, 196, 196, 0.3);
  border-radius: 11px;
}

/* Login */

.login_container {
  position: relative;
  top: 50px;
  padding: 6% 7% 4% 7%;

  background: #ffffff;
  border: 1px solid #ededed;
  box-sizing: border-box;
  box-shadow: 4px 4px 60px rgba(196, 196, 196, 0.3);
  border-radius: 11px;
}

.registration_container {
  position: relative;
  top: 1px;
  padding: 3%;
  border-radius: 21px;
  background: #ffffff;
  border: 1px solid #ededed;
  box-sizing: border-box;
  box-shadow: 4px 4px 60px rgba(196, 196, 196, 0.3);
}

.login_container .login_media {
  /* position: absolute; */
  display: block;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  left: 627px;
  top: 292px;
  padding-bottom: 10%;
}
.registration_container .login_media {
  /* position: absolute; */
  display: block;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  left: 600px;
  top: 292px;
  padding-bottom: 2%;
}

.logo_name {
  font-family: Pangram;
  font-style: normal;
  font-weight: bolder;
}

.input {
  border: none;
}
.login_container .login_header {
  position: relative;
  /* left: 10px; */

  font-family: Pangram;
  font-style: normal;
  font-weight: bold;
  text-align: left;
  font-size: 22px;
  color: #402e3c;
}

.registration_container .login_header {
  position: relative;
  font-family: Pangram;
  font-style: normal;
  font-weight: bold;
  text-align: left;
  font-size: 22px;
  color: #402e3c;
}

.input_container {
  text-align: center;
}

.login_container .login_btn {
  width: 141px;
  font-weight: bold;
  text-transform: capitalize;
  height: 48px;
  font-size: 16px;
  margin: 7%;
}

.registration_container .login_btn {
  width: 141px;
  font-weight: bold;
  text-transform: capitalize;
  height: 36px;
  font-size: 16px;
  margin: 1%;
}

/* For the button with gradient theme background colour */
.gradient_round_btn {
  font-family: Pangram;
  font-style: normal;
  font-weight: 500;
  /* line-height: 19px; */
  font-size: 16px;
  text-align: center;
  color: #ffffff;
  /* Gradient */

  background: linear-gradient(
    113.62deg,
    #8a2387 24.45%,
    #e94057 58.83%,
    #f27121 94.26%
  );
  border: none;
  border-radius: 32.6512px;
}
.gradient_green {
  font-family: Pangram;
  font-style: normal;
  font-weight: 500;
  /* line-height: 19px; */
  font-size: 16px;
  text-align: center;
  color: #ffffff;
  /* Gradient */

  background: linear-gradient(
    113.62deg,
    #8a2387 24.45%,
    #12b538 58.83%,
    #08981e 94.26%
  );
  border: none;
  border-radius: 32.6512px;
}
.gradient_red {
  font-family: Pangram;
  font-style: normal;
  font-weight: 500;
  /* line-height: 19px; */
  font-size: 16px;
  text-align: center;
  color: #ffffff;
  /* Gradient */

  background: linear-gradient(
    113.62deg,
    #8a2387 24.45%,
    #d01403 58.83%,
    #780303 94.26%
  );
  border: none;
  border-radius: 32.6512px;
}

.icon_color {
  color: #a49fa1;
  /* background: linear-gradient(113.62deg, #8A2387 24.45%, #E94057 58.83%, #F27121 94.26%); */
}

.login_btn:hover {
  cursor: pointer;
}

/* notification */

.notification_icon {
  /* color: white; */
  position: relative;
  /* top: 10px; */
}

/* Footer */
.Footer {
  margin-top: 2%;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 80px;
  background-color: grey;
}

.Footer span {
  height: 80px;
  color: white;
  padding-top: 30px;
  padding-left: 10px;
}
