@import "./fonts.css";

.Navbar-logo {
  height: 19px;
  /* float: left; */
  left: 32px;
  top: 28.16px;
}
.nav {
  padding-left: 2%;
  padding-right: 2%;
  width: 100%;
  height: 67px;
  background-color: #ffffff;
  box-shadow: none;
  border-bottom: "5px solid red";
}

.nav ul li {
  color: #402e3c;
  font-family: "DM Sans";
  font-size: 16px;
  font-weight: bold;
}

/* setting the active property of nav-option in navbar */
.active_nav a {
  background-image: linear-gradient(
    360deg,
    #8a2387 30%,
    #e94057 50.26%,
    #f27121 100%
  );
  background-image: -webkit-linear-gradient(
    360deg,
    #8a2387 30%,
    #e94057 50.26%,
    #f27121 100%
  );
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
}

.gradient_text {
  background-image: linear-gradient(
    0deg,
    #8a2387 0%,
    #e94057 49.26%,
    #f27121 100%
  );
  background-image: -webkit-linear-gradient(
    0deg,
    #8a2387 0%,
    #e94057 49.26%,
    #f27121 100%
  );
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
}
