@font-face {
    font-family: 'DM Sans';
    src: url('fonts/DMSans/DMSans-Regular.ttf');
}
@font-face {
    font-family: 'DM SansB';
    src: url('fonts/DMSans/DMSans-Bold.ttf');
}

@font-face {
    font-family: 'Pangram';
    src: url('fonts/Pangram/Pangram-Light.otf') format('opentype');
}

@font-face {
    font-family: 'PangramB';
    src: url('fonts/Pangram/Pangram-Bold.otf') format('opentype');
}

