


  .intro{
    position: relative;
    width: 122px;
    height: 38px;
    left: 128px;
    top: 81px;
    
    font-family: Pangram;
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 38px;
    text-transform: capitalize;

    color: #000000;

  }
  .description
  {
    font-family: DM Sans;
    font-style: italic;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    text-align: left;
    /* Grey */

    color: #402E3C;
    opacity: 0.8;
  }
  .search{  
    left: 82.73%;
    right: 17.01%;
    top: 17.32%;
    bottom: 82.32%;
  }


  .reloaded{
    left: 80.73%;
    right: 17.01%;
    top: 17.32%;
    bottom: 82.32%;
  }
  
  